define("discourse/plugins/discourse-legal-tools/discourse/connectors/admin-user-controls-after/download-all-wrapper", ["exports", "@glimmer/component", "@ember/object", "@glimmer/tracking", "discourse/lib/export-csv", "@ember/service"], function (_exports, _component, _object, _tracking, _exportCsv, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class UserExportComponent extends _component.default {
    static #_ = dt7948.g(this.prototype, "currentUser", [_service.inject]);
    #currentUser = (dt7948.i(this, "currentUser"), void 0);
    static #_2 = dt7948.g(this.prototype, "siteSettings", [_service.inject]);
    #siteSettings = (dt7948.i(this, "siteSettings"), void 0);
    static #_3 = dt7948.g(this.prototype, "dialog", [_service.inject]);
    #dialog = (dt7948.i(this, "dialog"), void 0);
    static #_4 = dt7948.g(this.prototype, "showAdminUserExport", [_tracking.tracked], function () {
      return false;
    });
    #showAdminUserExport = (dt7948.i(this, "showAdminUserExport"), void 0);
    constructor() {
      super(...arguments);
      this.initializeComponent();
    }
    initializeComponent() {
      const setting = this.siteSettings.legal_extended_user_download_admin;
      const user = this.currentUser;
      this.showAdminUserExport = this.calculateAllowed(setting, user);
    }
    calculateAllowed(setting, user) {
      switch (setting) {
        case 'disabled':
          return false;
        case 'admins_only':
          return user.admin;
        case 'admins_and_staff':
          return user.staff;
        default:
          return false;
      }
    }
    exportAdminUserArchive(user) {
      this.dialog.confirm({
        message: I18n.t("user.download_archive.confirm_all_admin", {
          username: user.username
        }),
        didConfirm: () => {
          (0, _exportCsv.exportEntity)('admin_user_archive', {
            user_id: user.id
          });
        }
      });
    }
    static #_5 = dt7948.n(this.prototype, "exportAdminUserArchive", [_object.action]);
  }
  _exports.default = UserExportComponent;
});